import React from 'react';
import jwt_decode from 'jwt-decode';
import { useEffect } from 'react';
import { useNavigate, redirect } from 'react-router-dom';
import login from '../../assets/login1.png';

const Login = () => {
  const navigate = useNavigate();

  function handleCallbackResponse(response) {
    const payload = {
      token: response.credential,
      expirationTime: new Date().getTime() + 24 * 60 * 60 * 1000
    }
    localStorage.setItem("userToken", JSON.stringify(payload))
    navigate("/chat")
  }

  useEffect(() => {
    // check if user data is present
    const token = localStorage.getItem("userToken")
    if (token !== null) {
      navigate("/chat")
    }
    
    /* global google */
    google.accounts.id.initialize({
      client_id: "1031433808322-9p93t2dpifo1ptg45b8dvpmmpisvftep.apps.googleusercontent.com",
      callback: handleCallbackResponse,
      ux_mode: "popup"
    })
  }, []);

  const onGoogleClick = () => {
    const createFakeGoogleWrapper = () => {
      const googleLoginWrapper = document.createElement("div");
      // Or you can simple hide it in CSS rule for custom-google-button
      googleLoginWrapper.style.display = "none";
      googleLoginWrapper.classList.add("custom-google-button");

      // Add the wrapper to body
      document.body.appendChild(googleLoginWrapper);

      // Use GSI javascript api to render the button inside our wrapper
      // You can ignore the properties because this button will not appear
      try {
        google.accounts.id.renderButton(googleLoginWrapper, {});
      } catch (error) {
        console.log(error)
      }

      const googleLoginWrapperButton =googleLoginWrapper.querySelector("div[role=button]");

      return {
        click: () => {
          googleLoginWrapperButton.click();
        },
      };
    };
    const googleButtonWrapper = createFakeGoogleWrapper();
    googleButtonWrapper.click()
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center">
      <div className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1">
        <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
          <div className="my-12 border-b text-center">
            <div>
              <img
                className="mx-auto h-auto w-60 px-3 py-2 rounded"
                src={login}
                alt="Aedeon"
              />
            </div>
            <h1 className="text-2xl xl:text-3xl font-extrabold my-6">Login</h1>
            <div className="mx-auto max-w-xs">
              <input
                className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                type="email"
                placeholder="Email"
              />
              <input
                className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                type="password"
                placeholder="Password"
              />
              <button className="mt-5 mb-2 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none">
                <span className="ml-3">Login</span>
              </button>
            </div>
            <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
              Or
            </div>
          </div>

          <div className="mt-4 flex flex-col items-center">
            <div className="w-full flex-1">
              <div className="flex flex-col items-center">
                {/* <GoogleLogin
                  clientId={googleId}
                  className="w-full max-w-xs font-bold shadow-sm rounded-lg py-3 bg-indigo-100 text-gray-800 flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline"
                  buttonText="Login with Google"
                  render={(renderProps) => (
                    <button
                      onClick={renderProps.onClick}
                      className="w-full max-w-xs font-bold shadow-sm rounded-lg py-3 bg-indigo-100 text-gray-800 flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline"
                      disabled={renderProps.disabled}
                    >
                      <div className="bg-white p-2 rounded-full">
                        <svg className="w-4" viewBox="0 0 533.5 544.3">
                          <path
                            d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                            fill="#4285f4"
                          />
                          <path
                            d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                            fill="#34a853"
                          />
                          <path
                            d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                            fill="#fbbc04"
                          />
                          <path
                            d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                            fill="#ea4335"
                          />
                        </svg>
                      </div>
                      <span className="ml-4">Login with Google</span>
                    </button>
                  )}
                  onSuccess={handleLogin}
                  onFailure={handleFailure}
                  cookiePolicy={'single_host_origin'}
                ></GoogleLogin> */}

                <button
                  onClick={onGoogleClick}
                  className="w-full max-w-xs font-bold shadow-sm rounded-lg py-3 bg-indigo-100 text-gray-800 flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline"
                >
                  <div className="bg-white p-2 rounded-full">
                    <svg className="w-4" viewBox="0 0 533.5 544.3">
                      <path
                        d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                        fill="#4285f4"
                      />
                      <path
                        d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                        fill="#34a853"
                      />
                      <path
                        d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                        fill="#fbbc04"
                      />
                      <path
                        d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                        fill="#ea4335"
                      />
                    </svg>
                  </div>
                  <span className="ml-4">Login with Google</span>
                </button>



                <button className="w-full max-w-xs font-bold shadow-sm rounded-lg py-3 bg-indigo-100 text-gray-800 flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline mt-5">
                  <div className="bg-white p-1 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="22"
                      height="22"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="#ff5722"
                        d="M6 6H22V22H6z"
                        transform="rotate(-180 14 14)"
                      ></path>
                      <path
                        fill="#4caf50"
                        d="M26 6H42V22H26z"
                        transform="rotate(-180 34 14)"
                      ></path>
                      <path
                        fill="#ffc107"
                        d="M26 26H42V42H26z"
                        transform="rotate(-180 34 34)"
                      ></path>
                      <path
                        fill="#03a9f4"
                        d="M6 26H22V42H6z"
                        transform="rotate(-180 14 34)"
                      ></path>
                    </svg>
                  </div>
                  <span className="ml-4">Login with Microsoft</span>
                </button>
              </div>
            </div>
          </div>
          <p className="mt-6 text-xs text-gray-600 text-center">
            I agree to abide by
            <a
              href="https://mactores.com/terms-and-conditions"
              className="border-b border-gray-500 border-dotted"
              target="_blank"
            >
              {' '}
              Terms of Service{' '}
            </a>
            and its
            <a
              href="https://mactores.com/privacy-policy"
              className="border-b border-gray-500 border-dotted"
              target="_blank"
            >
              {' '}
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
