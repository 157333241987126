import React, {  useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './Components/Dash/Dash'; 
import Login from "./Components/Login/Login";
import Chat from "./Components/Chat/Chat";
import "./App.css";


function App() {
  return (
      <Routes>
        <Route exact path="/chat" element={<Chat />} />
        <Route exact path="/" element={<Login />} />
        <Route exact path="/login" element={<Login />} />
        {/* <Route exact path="/dashboard" element={<Dashboard />} /> */}
      </Routes>
  );
}

export default App;
