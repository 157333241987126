const SearchCard = ({ text }) => {
    return (
      <div className="flex flex-row rounded-md p-2 my-2 items-center border-2">
        <div className="w-1/6">
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="h-4 w-4"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
          </svg>
        </div>
        <div className="w-5/6">
          <div className="text truncate">{text}</div>
        </div>
      </div>
    );
  };
  
const Conversations = ({ recentSearches }) => {
    const questions = [
      {
        question: "Who are my best customers and why?",
      },
      {
        question: "Which customers are likely to churn?",
      },
      {
        question: "Who bought in-store yesterday for the first time?",
      },
      {
        question: "How many unique customers do we really have?",
      },
      {
        question: "Who are our one-and-done customers?",
      },
      {
        question: "What common patterns drive second purchases?",
      },
      {
        question:
          "Which of my high-value customers hasn’t joined my loyalty program?",
      },
      {
        question: "How can I acquire new customers who look like my best ones?",
      },
      {
        question:
          "What are the next-best-actions and offers for specific customer segments?",
      },
      {
        question: "How effective is my marketing spend?",
      },
      {
        question:
          "How do I easily get a view of where my customers’ data reside to help support my privacy compliance programs?",
      },
    ];
    return (
      <>
        {/* Recent Searches */}
        <aside
          id="default-sidebar"
          className="flex flex-col w-1/4 border-r-2 overflow-y-auto custom-scrollbar"
          aria-label="Sidebar"
        >
          <div className="p-4 bg-white">
            <h2 className="text-gray-500 text-sm font-semibold mb-2">Chats</h2>
            {questions.map((question, index) => (
              <SearchCard text={question.question} key={`hem-${index}`}/>
            ))}
          </div>
        </aside>
      </>
    );
};

export default Conversations;
